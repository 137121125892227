import React from "react";
import heroImg from "../../assets/all_the_kings_women.jpg";

const AllTheKingsWomen = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <img className="object-cover object-center max-h-[32rem] rounded-3xl" src={heroImg} />
      <div className="max-w-screen-lg">
        <h1 className="font-header text-4xl text-center mt-8">All The Kings Women</h1>
        <p className="text-center text-2xl font-header my-4">By Luigi Januzzi</p>
        <p className="text-center text-xl font-header my-8">March 29 - April 13, 2024</p>
        <p>
          The story of Elvis Presley told through the eyes of 17 women! Some enthralled! Some appalled! ALL OBSESSED! A
          fast paced series of 5 comedic plays and 3 monologues based on the Life of Elvis Presley. From Tupelo
          Mississippi where 11 year old Elvis wanted a BB gun instead of a guitar, to Richard Nixon’s office, to Andy
          Warhol’s studio, from Cadillac salesman to Graceland guards, this is a touching, bring-the-family comedy with
          a heart that captures the effects that fame, generosity & just being a nice guy can bring to others!
        </p>
      </div>
    </div>
  );
};

export default AllTheKingsWomen;
