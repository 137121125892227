import React from "react";
import logo from "../assets/logo.png";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex items-center">
              <img src={logo} className="h-8 mr-3" alt="Sidekick Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                Sidekick
              </span>
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Visit Us
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li>
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=tsawwassen%20arts%20centre"
                    className="hover:underline "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tsawwassen Arts Centre
                    <br />
                    1172 56th Street
                    <br />
                    Delta, B.C.
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Contact
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="tel:+16042882415" className="hover:underline">
                    Call us
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:sidekickplayers@outlook.com"
                    className="hover:underline"
                  >
                    Email us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2 mt-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
            Land Ackowledgement
          </h2>
          <p className="text-gray-500 dark:text-gray-400 font-medium">
            The Sidekick Players Club acknowledge that we are on the
            traditional, ancestral, and unceded territories of the scəw̓aθən
            (Tsawwassen) and xʷməθkʷəy̓əm (Musqueam) First Nations and all
            Hun’qumi’num speaking people. We extend our appreciation to these
            First Nations for the opportunity to live, work, and create on their
            territory.
          </p>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            Copyright © 2024 The Sidekick Players Club™. All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
            <a
              href="https://www.facebook.com/sidekickplayers/"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 8 19"
              >
                <path
                  fillRule="evenodd"
                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Facebook page</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
