import React from "react";
import heroImg from "../assets/3.png";

const Contact = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <img className="w-full object-cover object-center max-h-[32rem] rounded-3xl" src={heroImg} />
      <div className="max-w-screen-lg">
        <h1 className="font-header text-4xl text-center my-8">Site Under Construction</h1>
        <p>Check back soon for updates.</p>
      </div>
    </div>
  );
};

export default Contact;
