import React from "react";
import Card from "../components/Card";
import Carousel from "../components/Carousel";
// import image1 from "../assets/senior_follies.png";
import image2 from "../assets/rabbit_hole.jpg";
import image3 from "../assets/all_the_kings_women.jpg";
import image4 from "../assets/the_long_weekend.jpg";

const cards = [
  // {
  //   image: image1,
  //   header: "Senior Follies",
  //   byline: "By Billie Wayne St. John",
  //   date: "October 13 - October 28, 2023",
  //   link: "/shows/senior-follies",
  // },
  {
    image: image2,
    header: "Rabbit Hole",
    byline: "By David Lindsay-Abaire",
    date: "January 19 - February 3, 2024",
    link: "/shows/rabbit-hole",
  },
  {
    image: image3,
    header: "All the King's Women",
    byline: "By Luigi Januzzi",
    date: "March 29 - April 13, 2024",
    link: "/shows/all-the-kings-women",
  },
  {
    image: image4,
    header: "The Long Weekend",
    byline: "By Norm Foster",
    date: "June 14 - June 29, 2024",
    link: "/shows/the-long-weekend",
  },
];

const auditionCTA = {
  header: "Auditions Open Soon!",
  date: "April 23 and April 24, 2024",
  link: "/auditions",
};

const Home = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 text-center dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <div className="max-w-screen-lg flex flex-col items-center">
        <Card card={auditionCTA} />
        <h1 className="font-header text-4xl my-8 pt-8">Current Productions</h1>
        <p>
          Take a look at the exciting new shows in the works for the Sidekick
          Players Club's 2023-2024 season!
        </p>
      </div>
      <Carousel cards={cards} />
    </div>
  );
};

export default Home;
