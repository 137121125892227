import React from "react";
import heroImg from "../../assets/senior_follies.png";

const SeniorFollies = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <img className="w-full object-cover object-center max-h-[32rem] rounded-3xl" src={heroImg} />
      <div className="max-w-screen-lg">
        <h1 className="font-header text-4xl text-center mt-8">Senior Follies</h1>
        <p className="text-center text-2xl font-header my-4">By Billie Wayne St. John</p>
        <p className="text-center text-xl font-header my-8">October 13 - October 28, 2023</p>
        <p>
          The rocking is not done in chairs at the Pleasant Valley Retirement Home, especially since Howard discovered
          Viagra! The feisty divorcee and lovely widow who are constantly scurrying out of his reach and refuse to play
          strip poker or skinny dip in the hot tub, breathe a sigh of relief when a new resident actually seems to enjoy
          Howards advances – Don’t miss the hilarity in this wonderful comedy
        </p>
      </div>
    </div>
  );
};

export default SeniorFollies;
