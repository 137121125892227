import React from "react";
import heroImg from "../../assets/the_long_weekend.jpg";

const TheLongWeekend = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <img className="object-cover object-center max-h-[32rem] rounded-3xl" src={heroImg} />
      <div className="max-w-screen-lg">
        <h1 className="font-header text-4xl text-center mt-8">The Long Weekend</h1>
        <p className="text-center text-2xl font-header my-4">By Norm Foster</p>
        <p className="text-center text-xl font-header my-8">June 14 – June 29, 2024</p>
        <p>
          During a weekend visit between two couples, the true nature of the relationships come to the surface as the
          four so-called ‘best friends’ slowly discover how they really feel about each other. Successful lawyer Max and
          his wife Wynn are about to show off their beautiful new summer home to their best friends, Roger and Abby. But
          it doesn’t take long to uncover the true feelings behind this long friendship. What is meant to be a relaxing
          holiday turns into a battle of wits as their pasts catch up with them, the gloves come off and old scores get
          settled in comedic fashion.
        </p>
      </div>
    </div>
  );
};

export default TheLongWeekend;
