import React from "react";
import heroImg from "../assets/all_the_kings_women.jpg";

const Auditions = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <div className="max-w-screen-lg flex flex-col gap-4">
        <h2 className="font-header text-4xl text-center my-8">
          Auditions Open Soon!
        </h2>
        <p>
          The Sidekick Players Club is auditioning for their last production of
          the season. Rounding out a very successful season is{" "}
          <a href="/shows/the-long-weekend" className="underline font-bold">
            The Long Weekend
          </a>{" "}
          by Norm Foster and directed by Laura Sutherland-Smith and Carroll
          Lefebvre.
        </p>
        <p>
          When Max and Wynn invite their friends Roger and Abby up for a weekend
          at their country home, the true nature of their relationships come to
          the surface during a weekend visit between two couples. There are
          plenty of surprises along the way in this comedy of manners.
        </p>
        <p>
          Directors Sutherland-Smith and Lefebvre are looking for two couples...
          <i> two males and two females in their 50's </i> Auditions will be
          held on <b>Tuesday, April 23 and 24th from 7pm to 9pm.</b> There are
          no appointments; first come, first served basis. The audition will be
          a cold read from the script. The Long Weekend scripts are available at
          the Vancouver Public Librray for perusal prior to the audition.
          Sidekick is not a for profit organization and there are no
          renumerations paid. All those cast must become members of Sidekick.
          Membership is $10 per person. Rehearsals will commence the week of
          April 20, 2024.
        </p>
        <p>
          Please send a picture and resume to{" "}
          <a
            href="mailto:sidekickplayers@outlook.com"
            className="underline font-bold"
          >
            sidekickplayers@outlook.com
          </a>
          .
        </p>
        <p>
          If you aren't interested in being on the stage, we would love to have
          you help with the production! Please contact the above email address
          for any further information.
        </p>
      </div>
    </div>
  );
};

export default Auditions;
