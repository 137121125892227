import React from "react";

const Card = ({ card }) => {
  return (
    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href={card.link}>
        <img class="rounded-t-lg" src={card.image} alt="" />
      </a>
      <div class="p-5">
        <h5 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{card.header}</h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{card.byline}</p>
        <p class="mb-3 font-normal text-xl text-gray-700 dark:text-gray-400">{card.date}</p>
        <a
          href={card.link}
          class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-primary-dark focus:ring-4 focus:outline-none focus:ring-primary-lighter dark:bg-primary dark:hover:bg-primary-dark dark:focus:ring-primary-darker"
        >
          Read more
          <svg
            class="w-3.5 h-3.5 ml-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Card;
