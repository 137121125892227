import React from "react";
import heroImg from "../../assets/rabbit_hole.jpg";

const RabbitHole = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <img className="object-cover object-center max-h-[32rem] rounded-3xl" src={heroImg} />
      <div className="max-w-screen-lg">
        <h1 className="font-header text-4xl text-center mt-8">Rabbit Hole</h1>
        <p className="text-center text-2xl font-header my-4">By David Lindsay-Abaire</p>
        <p className="text-center text-xl font-header my-8">January 19 - February 3, 2024</p>
        <p>
          Becca and Howie Corbett have everything a family could want, until a life-shattering accident turns their
          world upside down and leaves the couple drifting perilously apart. Rabbit Hole charts their bittersweet search
          for comfort in the darkest of places and for a path that will lead them back into the light of day.
        </p>
        <p className="my-4">Winner of the 2007 Pulitzer prize.</p>
      </div>
    </div>
  );
};

export default RabbitHole;
